import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

const ChatButton = () => {
  const [bottomOffset, setBottomOffset] = useState("1.25rem");

  useEffect(() => {
    const checkForOverlap = () => {
      const chatButton = document.getElementById("chat-button");
      const cookieConsent = document.querySelector(".CookieConsent");

      if (!chatButton) return;

      let newOffset = 20; // Default bottom offset in pixels

      if (cookieConsent) {
        const cookieHeight = cookieConsent.offsetHeight;
        // Add extra padding above the cookie banner
        newOffset = cookieHeight + 20;
      }

      // Also check for other buttons
      const buttons = document.querySelectorAll(
        'button, .button, [role="button"]'
      );
      buttons.forEach((button) => {
        if (button.closest(".CookieConsent")) return; // Skip buttons inside cookie banner

        const buttonRect = button.getBoundingClientRect();
        const chatButtonRect = chatButton.getBoundingClientRect();

        if (
          buttonRect.right > chatButtonRect.left &&
          buttonRect.left < chatButtonRect.right &&
          buttonRect.bottom > chatButtonRect.top &&
          buttonRect.top < chatButtonRect.bottom
        ) {
          const buttonOffset = window.innerHeight - buttonRect.top + 20;
          newOffset = Math.max(newOffset, buttonOffset);
        }
      });

      setBottomOffset(`${newOffset}px`);
    };

    // Check for overlap on mount and when window resizes
    window.addEventListener("resize", checkForOverlap);

    // Add mutation observer to detect when cookie banner is added/removed
    const observer = new MutationObserver(checkForOverlap);
    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });

    checkForOverlap();

    return () => {
      window.removeEventListener("resize", checkForOverlap);
      observer.disconnect();
    };
  }, []);

  return (
    <a
      id="chat-button"
      href="https://wa.me/27861017722?text=Hi&utm_source=website&utm_medium=Contact+us+button&utm_campaign=Website-Whatsapp"
      target="_blank"
      rel="noopener noreferrer"
      className="fixed right-3 md:right-5 bg-green-500 text-white p-2 md:p-4 rounded-full shadow-lg cursor-pointer hover:bg-green-600 transition flex items-center space-x-1 md:space-x-2 animate-slide-in scale-75 md:scale-100"
      style={{
        zIndex: 1000,
        bottom: bottomOffset,
      }}
    >
      <FontAwesomeIcon icon={faWhatsapp} className="text-lg md:text-2xl" />
      <span className="text-xs md:text-sm font-semibold">Chat with Us</span>
    </a>
  );
};

export default ChatButton;
