import { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import GetQuoteDetails from "components/get-quote/get-quote-details";
import ManageBooking from "components/manage-booking";
import { UserContext } from "contexts/user";
import D2DGetQuoteDetails from "components/d2d/d2d-get-quote-details";

const GetQuote = ({ activeTab = 1 }) => {
  const [manageDetails, setManageDetails] = useState({});
  const [selectedTab, setSelectedTab] = useState(activeTab);
  let { user } = useContext(UserContext);

  return (
    <>
      <div className="rounded-md bg-gray-100 text-sm">
        <nav
          className="relative z-0 rounded-md shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          <div
            onClick={() => setSelectedTab(1)}
            className={`${
              selectedTab === 1 ? `quote-tabs-active` : `quote-tabs-inactive`
            } quote-tabs rounded-tl-md min-w-0 bg-bl`}
            aria-current="page"
          >
            <span>Book a Vehicle</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 1 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>

          <div
            onClick={() => setSelectedTab(2)}
            className={`${
              selectedTab === 2 ? `quote-tabs-active` : `quote-tabs-inactive`
            } quote-tabs`}
          >
            <span>Manage Booking</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 2 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>

          <div
            onClick={() => setSelectedTab(3)}
            className={`${
              selectedTab === 3 ? `quote-tabs-active` : `quote-tabs-inactive`
            } quote-tabs`}
          >
            <span>Door2Door</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 3 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>

          <NavLink
            to="/transfer-services/chauffeur-drive/"
            className={`${
              selectedTab === 4 ? `quote-tabs-active` : `quote-tabs-inactive`
            } rounded-tr-md quote-tabs`}
          >
            <span>Chauffeur Drive</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 4 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </NavLink>
        </nav>

        {selectedTab === 1 && (
          <>
            <GetQuoteDetails
              manageDetails={manageDetails}
              setManageDetails={setManageDetails}
            />
          </>
        )}

        {selectedTab === 2 && (
          <>
            <ManageBooking
              manageDetails={manageDetails}
              setManageDetails={setManageDetails}
            />
          </>
        )}

        {selectedTab === 3 && (
          <div id="door2Door">
            <D2DGetQuoteDetails
              manageDetails={manageDetails}
              setManageDetails={setManageDetails}
            />
          </div>
        )}

        {selectedTab === 4 && (
          <div id="chauffeurDrive">
            <div className="grid gap-4 p-5 text-center lg:grid-cols-12 lg:p-10">
              <div className="col-span-12 bg-gray-100">
                <h2 className="text-xl mb-2 lg:text-2xl">
                  Arrive on time, relaxed and refreshed with Bluu Chauffeur
                  Drive.
                </h2>
                Let Bluu Chauffeur Drive provide you with your own professional
                chauffeur, your choice of a luxury vehicle and a service
                tailored to your individual itinerary, be it business or
                leisure.
              </div>
              <div className="col-span-12 ">
                <NavLink
                  to="/transfer-services/chauffeur-drive/"
                  className="btn-light py-2"
                >
                  Read More
                </NavLink>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
export default GetQuote;
