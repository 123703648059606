import { useState, useEffect, useContext, useMemo } from "react";
import { add, isBefore } from "date-fns";
import Loader from "components/loader";
import D2DSectionSelectBranch from "components/d2d/d2d-section-select-branch";
import D2DGoogleMap from "components/d2d/d2d-google-map";
import { API_ROUTES } from "routes/api";
import { ApiConsumer } from "api/ApiConsumer";
import { useHistory } from "react-router-dom";
import { CONSTANTS } from "contants/constants";
import { WEBSITE_ROUTES } from "routes/website";
import { QuoteContext, SaveQuoteContext } from "contexts/quote";
import { VehicleContext } from "contexts/vehicle";
import { BranchContext } from "contexts/branch";
import { DetailsContext } from "contexts/details";
import { useUtils } from "hooks/utils";
import * as Sentry from "@sentry/react";
import { useLoadScript } from "@react-google-maps/api";
import { UserContext } from "contexts/user";

const D2DGetQuoteDetails = () => {
  let { user } = useContext(UserContext);

  console.log(user);
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: CONSTANTS.GOOGLE_MAPS_API,
    libraries: CONSTANTS.GOOGLE_MAPS_LIBRARIES,
  });

  const [isVanRental, setIsVanRental] = useState(false);

  const [showLoader, setShowLoader] = useState(true); //Default true until branches have loaded
  const [errorMessage, setErrorMessage] = useState("");

  const { details, setDetails } = useContext(DetailsContext);
  const { validDetails, setValidDetails } = useState([]);

  const [additionaCollectionAddresses, setAdditionaCollectionAddresses] =
    useState(false);

  const [additionaDeliveryAddresses, setAdditionaDeliveryAddresses] =
    useState(false);

  let history = useHistory();
  let { setQuote } = useContext(QuoteContext);
  let { setSaveQuote } = useContext(SaveQuoteContext);
  let { setVehicle } = useContext(VehicleContext);
  let { branches, setBranches } = useContext(BranchContext);
  let { getDate, getTime } = useUtils();

  useEffect(() => {
    //Restore default
    const dateCoeff = 1000 * 60 * 15;
    const defaultDate = new Date();
    const defaultTime = new Date();
    const pickupDate = defaultTime.setHours(
      defaultTime.getHours() + 2,
      defaultTime.getMinutes() + 30,
      0,
      0
    );

    setDetails({
      [`${CONSTANTS.PICKUP.toUpperCase().replace("-", "")}_DATE`]: new Date(
        pickupDate
      ),
      [`${CONSTANTS.PICKUP.toUpperCase().replace("-", "")}_TIME`]: new Date(
        Math.ceil(pickupDate / dateCoeff) * dateCoeff
      ),
      [`${CONSTANTS.PICKUP.toUpperCase().replace("-", "")}_BRANCH`]: "JS",
      [`${CONSTANTS.DROPOFF.toUpperCase().replace("-", "")}_BRANCH`]: "JS",
      [`${CONSTANTS.DROPOFF.toUpperCase().replace("-", "")}_DATE`]: new Date(
        pickupDate
      ),
      [`${CONSTANTS.DROPOFF.toUpperCase().replace("-", "")}_TIME`]: new Date(
        add(new Date(Math.ceil(pickupDate / dateCoeff) * dateCoeff), {
          minutes: 1,
        })
      ),
      accountType: "I",
      accountNo: "",
      accountName: "",
      rateCode: "",
      RESIDENCE: "L",
      isD2D: true,
    });
  }, []);

  const checkAvailability = () => {
    setErrorMessage("");
    setVehicle({});

    //Check if booking is in no less than 2h15m time
    const pickupDateTime = new Date(
      `${getDate(details.PICKUP_DATE)} ${getTime(details.PICKUP_TIME)}`
    );
    const dropoffDateTime = new Date(
      `${getDate(details.DROPOFF_DATE)} ${getTime(details.DROPOFF_TIME)}`
    );
    const validBookingDateTime = add(new Date(), { hours: 2, minutes: 25 }); //Give 5 mins grace time

    if (details.moP_Type === "selected") {
      setErrorMessage("Please select Method of payment.");
      setShowLoader(false);
    } else if (details.moP_Type === "AC" && details.accountNo === "") {
      setErrorMessage("Please enter Account number.");
      setShowLoader(false);
    } else if (details.PICKUP_BRANCH_ADDRESS === "") {
      setErrorMessage("Please enter Pick-up location.");
      setShowLoader(false);
    } else if (details.DROPOFF_BRANCH_ADDRESS === "") {
      setErrorMessage("Please enter Drop-off-up location.");
      setShowLoader(false);
    } else if (
      details.isFlight === "Yes" &&
      details.pickupFlightType === "selected"
    ) {
      setErrorMessage("Please select Flight type.");
      setShowLoader(false);
    } else if (
      details.isFlight === "Yes" &&
      details.pickupFlightNumber === ""
    ) {
      setErrorMessage("Please enter Flight number.");
      setShowLoader(false);
    } else if (isBefore(pickupDateTime, validBookingDateTime)) {
      setErrorMessage("Pick-up time must be 2 hours 30 minutes from now.");
      setShowLoader(false);
    } /* else if (isBefore(dropoffDateTime, pickupDateTime)) {
      setErrorMessage('Drop-off date cannot be before pick-up.')
      setShowLoader(false)
    } else if (isBefore(dropoffDateTime, add(pickupDateTime, { hours: 2 }))) {
      setErrorMessage('Drop-off time cannot be within 2 hours of pick-up.')
      setShowLoader(false)
    }*/ else {
      setShowLoader(true);

      //Gets accounts by account number
      ApiConsumer.get(API_ROUTES.DOOR2DOOR.RATES.BY_ACCOUNT(details.accountNo))
        .then((res) => {
          if (res.data.length) {
            let selectedAccount = [];
            res.data.forEach((account) => {
              if (
                parseFloat(details.tripKms) >= parseFloat(account.fromKms) &&
                parseFloat(details.tripKms) <= parseFloat(account.toKms)
              )
                selectedAccount.push(account);
            });
            if (selectedAccount.length !== 0) {
              setDetails((prevState) => ({
                ...prevState,
                [`accountName`]: selectedAccount[0].accountName,
              }));

              const payload = {
                rateCode: selectedAccount[0].rateCode,
                pickUpBranch: details.PICKUP_BRANCH,
                pickUpDateTime: `${getDate(details.PICKUP_DATE)} ${getTime(
                  details.PICKUP_TIME
                )}`,
                dropOffBranch: details.DROPOFF_BRANCH,
                dropOffDateTime: `${getDate(details.DROPOFF_DATE)} ${getTime(
                  details.DROPOFF_TIME
                )}`,
                accountType: details.accountType,
                accountNo: details.accountNo,
                foreignLocal: "L",
              };

              ApiConsumer.post(API_ROUTES.DOOR2DOOR.AVAILABILITY, payload)
                .then((res) => {
                  if (
                    Array.isArray(res.data?.errors?.error) &&
                    res.data?.errors?.error.length > 0
                  ) {
                    if (
                      !res.data?.errors?.error[0].errorMessage
                        .toLowerCase()
                        .includes("success")
                    ) {
                      setErrorMessage(
                        res.data?.errors?.error[0].errorMessage
                          .replace("Bidvest", "Bluu")
                          .replace("bidvestcarrental.co.za", "bcr.co.za")
                      );
                      return;
                    }
                    if (res.data.rates.rate[0].charges.length === 0) {
                      setErrorMessage(
                        "No vehicles available for selected date/time. Contact us on 086 101 7722 or reservations@bcr.co.za"
                      );
                      return;
                    }

                    setQuote({});
                    setSaveQuote({});
                    setVehicle({});

                    //Reset modify if making new booking
                    setDetails((prevState) => ({
                      ...prevState,
                      [`modify`]: false,
                    }));

                    if (res.data.rates.rate.length > 0)
                      history.push({
                        pathname: WEBSITE_ROUTES.AVAILABILITY.link,
                        state: {
                          rates: res.data.rates.rate,
                          otherRates: res.data.otherRates,
                          payload: payload,
                        },
                      });
                    else {
                      setErrorMessage(
                        "Pick Up not in the Branch hours. Phone Bluu 086 101 7722 or refer to Location details"
                      );
                    }
                  }
                })
                .catch((err) => {
                  Sentry.captureException(err);
                })
                .finally(() => setShowLoader(false));
            } else {
              setErrorMessage("Rate Code in range could not be found.");
              setShowLoader(false);
            }
          } else {
            setErrorMessage(
              "Sorry - it appears there are no D2D rates linked to your account. Please contact your Bluu Car Rental Sales Representative or email CustomerCare@bcr.co.za"
            );
            setShowLoader(false);
          }
        })
        .catch((err) => console.error(err));
    }
  };

  console.log("Details:", user);

  useEffect(() => {
    ApiConsumer.get(API_ROUTES.BRANCHES.ALL)
      .then((res) => {
        setBranches(res.data);
        let vanSelected = []; //Check if selected is van or car
        vanSelected = branches.filter(
          (branch) =>
            details[
              `${CONSTANTS.PICKUP.toUpperCase().replace("-", "")}_BRANCH`
            ] === branch.branchCode && branch.isVanRental === true
        );
        setIsVanRental(vanSelected.length ? true : false);
        setShowLoader(false);
      })
      .catch((err) => console.error(err));
  }, []);
  useEffect(() => {
    setDetails((prevState) => ({
      ...prevState,
      ["COLLECTIONADDRESS1_BRANCH_ADDRESS"]: null,
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["COLLECTIONADDRESS2_BRANCH_ADDRESS"]: null,
    }));
  }, [additionaCollectionAddresses]);
  useEffect(() => {
    setDetails((prevState) => ({
      ...prevState,
      ["DELIVERYADDRESS1_BRANCH_ADDRESS"]: null,
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["DELIVERYADDRESS2_BRANCH_ADDRESS"]: null,
    }));
  }, [additionaDeliveryAddresses]);

  const [mapLocations, setMapLocations] = useState({
    origin: null,
    /* waypoint1: null,
    waypoint2: null,
    waypoint3: null,
    waypoint4: null, */
    destination: null,
  });

  useEffect(() => {
    /*
    Load defaults
    */
    setDetails((prevState) => ({
      ...prevState,
      ["moP_Type"]:
        Object.keys(user).length === 0 || user.accountNo === null
          ? "selected"
          : "AC",
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["isFlight"]: "No",
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["pickupFlightType"]: "selected",
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["numberPassengers"]: 1,
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["pickupFlightNumber"]: "",
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["accountNo"]:
        Object.keys(user).length === 0 || user.accountNo === null
          ? ""
          : user.accountNo,
    }));
    setDetails((prevState) => ({
      ...prevState,
      ["accountType"]: "C",
    }));
    setDetails((prevState) => ({
      ...prevState,
      [`isD2D`]: true,
    }));
  }, []);

  const onisFlightChange = (value) => {
    if (value === "No") {
      setDetails((prevState) => ({
        ...prevState,
        ["pickupFlightType"]: "selected",
      }));
      setDetails((prevState) => ({
        ...prevState,
        ["pickupFlightNumber"]: "",
      }));
    }
  };

  const [disableDistanceButton, setDisableDistanceButton] = useState(true);
  const [validDistance, setValidDistance] = useState(false);

  useEffect(() => {
    console.log("Map Locations:", mapLocations);

    // Check if we have both pickup and dropoff locations with valid place IDs
    const hasPickup =
      mapLocations.pickup?.place_id || mapLocations.pickup?.geometry?.location;
    const hasDropoff =
      mapLocations.dropoff?.place_id ||
      mapLocations.dropoff?.geometry?.location;

    setDisableDistanceButton(!(hasPickup && hasDropoff));
  }, [mapLocations]);

  const handleCalculateDistance = () => {
    if (!mapLocations.pickup || !mapLocations.dropoff) {
      setErrorMessage("Please enter both pickup and drop-off locations");
      return;
    }

    // Validate that both locations have either place_id or geometry
    if (
      (!mapLocations.pickup.place_id && !mapLocations.pickup.geometry) ||
      (!mapLocations.dropoff.place_id && !mapLocations.dropoff.geometry)
    ) {
      setErrorMessage(
        "Invalid location selected. Please select locations from the dropdown suggestions."
      );
      return;
    }

    setValidDistance(true);
    setErrorMessage("");
  };

  return (
    <>
      <div className="grid gap-3 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8 lg:py-3">
        <div className="col-span-12 lg:col-span-4">
          <label
            htmlFor="MOP_Type"
            className="block text-sm font-medium text-gray-700"
          >
            Method of payment: <span className="text-red-600">*</span>
          </label>
          <select
            name="MOP_Type"
            id="MOP_Type"
            defaultValue="selected"
            autoComplete="title"
            className={`style-select shadow-sm border-0`}
            onChange={(event) => {
              setDetails((prevState) => ({
                ...prevState,
                ["moP_Type"]: event.target.value,
              }));
              let tmpAcc = "";
              switch (event.target.value) {
                case "VS":
                  tmpAcc = "10001000000";
                  break;
                case "MC":
                  tmpAcc = "10000700006";
                  break;
                case "DC":
                  tmpAcc = "10000400003";
                  break;
                case "AX":
                  tmpAcc = "10000100009";
                  break;
                default:
              }
              setDetails((prevState) => ({
                ...prevState,
                [`accountNo`]: tmpAcc,
              }));
            }}
            value={details.moP_Type}
            disabled={
              Object.keys(user).length === 0 || user.accountNo === null
                ? false
                : true
            }
          >
            <option value="selected">Select option</option>
            <option value="AX">American Express Card</option>
            <option value="DC">Diners Club</option>
            <option value="MC">Master Card</option>
            <option value="VS">Visa Card</option>
            {user.accountNo !== null ||
              (user.accountEmail.includes("@bcr.co.za") && (
                <option value="AC">Billback to the Account</option>
              ))}
          </select>
        </div>
        {details.moP_Type === "AC" && (
          <div className="col-span-12 lg:col-span-4">
            <label
              htmlFor="accountNo"
              className="block text-sm font-medium text-gray-700 "
            >
              Account number:
            </label>
            <input
              id="accountNo"
              name="accountNo"
              type="text"
              className={`style-input shadow-sm border-0`}
              onChange={(event) => {
                setDetails((prevState) => ({
                  ...prevState,
                  [`accountNo`]: event.target.value,
                }));
              }}
              value={details.accountNo}
              disabled={
                Object.keys(user).length === 0 || user.accountNo === null
                  ? false
                  : true
              }
            />
          </div>
        )}

        <div className="col-span-8 relative text-base lg:col-span-2">
          <label
            htmlFor="numberPassengers"
            className="block text-sm font-medium text-gray-700"
          >
            Passengers: <span className="text-red-600">*</span>
          </label>
          <select
            name="numberPassengers"
            id="numberPassengers"
            defaultValue="selected"
            autoComplete="title"
            className={`style-select shadow-sm border-0`}
            onChange={(event) => {
              setDetails((prevState) => ({
                ...prevState,
                ["numberPassengers"]: event.target.value,
              }));
            }}
            value={details.numberPassengers}
          >
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
            <option value="6">6</option>
            <option value="7">7</option>
            <option value="8">8</option>
            <option value="9">9</option>
            <option value="10">10</option>
            <option value="11">11</option>
            <option value="12">12</option>
            <option value="13">13</option>
            <option value="14">14</option>
            <option value="15">15</option>
            <option value="16">16</option>
            <option value="17">17</option>
            <option value="18">18</option>
            <option value="19">19</option>
            <option value="20">20</option>
            <option value="21">21</option>
            <option value="22">22</option>
          </select>
        </div>
      </div>

      <div
        className={`grid gap-2 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8`}
      >
        {isLoaded && (
          <D2DSectionSelectBranch
            type={CONSTANTS.PICKUP}
            setMapLocations={setMapLocations}
            setValidDistance={setValidDistance}
          />
        )}

        <div className="col-span-12 relative text-base lg:col-span-12 mt-2">
          <label
            htmlFor="isFlight"
            className="block text-sm font-medium text-gray-700 float-left mt-4 mr-2"
          >
            Would you like to add additional pick-up addresses?{" "}
          </label>
          <select
            name="additionaCollectionAddresses"
            id="additionaCollectionAddresses"
            defaultValue="selected"
            autoComplete="title"
            className={`style-select shadow-sm border-0 float-left w-auto`}
            onChange={(event) => {
              setAdditionaCollectionAddresses(JSON.parse(event.target.value));
              setValidDistance(false); // Reset distance calculation
              setErrorMessage(""); // Clear any error messages
            }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>

        {isLoaded && additionaCollectionAddresses && (
          <D2DSectionSelectBranch
            type={CONSTANTS.D2D_COLLECTION_ADDRESS1}
            setMapLocations={setMapLocations}
            setValidDistance={setValidDistance}
          />
        )}

        {isLoaded && additionaCollectionAddresses && (
          <D2DSectionSelectBranch
            type={CONSTANTS.D2D_COLLECTION_ADDRESS2}
            setMapLocations={setMapLocations}
            setValidDistance={setValidDistance}
          />
        )}

        <div className="col-span-12 lg:col-span-12 mt-2">
          <label className="block text-sm font-medium text-gray-700">
            Additional pick-up instructions:
          </label>
          <input
            id="areaDetails"
            name="areaDetails"
            type="text"
            className={`style-input shadow-sm border-0 mt-2`}
            onChange={(event) => {
              setDetails((prevState) => ({
                ...prevState,
                [`PICKUP_AREA_DETAILS`]: event.target.value,
              }));
            }}
            value={details[`PICKUP_AREA_DETAILS`]}
          />
        </div>

        <>
          <div className="col-span-4 relative text-base lg:col-span-2 mt-2">
            <label
              htmlFor="isFlight"
              className="block text-sm font-medium text-gray-700"
            >
              Flight?
            </label>
            <select
              name="isFlight"
              id="isFlight"
              defaultValue="selected"
              autoComplete="title"
              className={`style-select shadow-sm border-0`}
              onChange={(event) => {
                setDetails((prevState) => ({
                  ...prevState,
                  ["isFlight"]: event.target.value,
                }));
                onisFlightChange(event.target.value);
              }}
              value={details.isFlight}
            >
              <option value="No">No</option>
              <option value="Yes">Yes</option>
            </select>
          </div>
          {details.isFlight === "Yes" && (
            <>
              <div className="col-span-8 lg:col-span-4 mt-2">
                <label className="block text-sm font-medium text-gray-700">
                  Flight type: <span className="text-red-600">*</span>
                </label>
                <select
                  name="pickupFlightType"
                  id="pickupFlightType"
                  defaultValue="selected"
                  autoComplete="title"
                  className={`style-select shadow-sm border-0`}
                  onChange={(event) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      ["pickupFlightType"]: event.target.value,
                    }));
                  }}
                  value={details.pickupFlightType}
                >
                  <option value="selected">Select option</option>
                  <option value="Domestic">Domestic arrivals</option>
                  <option value="International">International arrivals</option>
                </select>
              </div>

              <div className="col-span-12 lg:col-span-6 mt-2">
                <label className="block text-sm font-medium text-gray-700">
                  Flight number: <span className="text-red-600">*</span>
                </label>
                <input
                  id="pickupFlightNumber"
                  name="pickupFlightNumber"
                  type="text"
                  className={`style-input shadow-sm border-0 mt-2`}
                  onChange={(event) => {
                    setDetails((prevState) => ({
                      ...prevState,
                      ["pickupFlightNumber"]: event.target.value,
                    }));
                  }}
                  value={details.pickupFlightNumber}
                />
              </div>
            </>
          )}
        </>
      </div>
      <div
        className={`grid gap-2 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8`}
      >
        {isLoaded && (
          <D2DSectionSelectBranch
            type={CONSTANTS.DROPOFF}
            setMapLocations={setMapLocations}
            setValidDistance={setValidDistance}
          />
        )}

        <div className="col-span-12 relative text-base lg:col-span-12 mt-2">
          <label
            htmlFor="isFlight"
            className="block text-sm font-medium text-gray-700 float-left mt-4 mr-2"
          >
            Would you like to add additional drop-off addresses?{" "}
          </label>
          <select
            name="additionaDeliveryAddresses"
            id="additionaDeliveryAddresses"
            defaultValue="selected"
            autoComplete="title"
            className={`style-select shadow-sm border-0 float-left w-auto`}
            onChange={(event) => {
              setAdditionaDeliveryAddresses(JSON.parse(event.target.value));
              setValidDistance(false); // Reset distance calculation
              setErrorMessage(""); // Clear any error messages
            }}
          >
            <option value={false}>No</option>
            <option value={true}>Yes</option>
          </select>
        </div>
        {isLoaded && additionaDeliveryAddresses && (
          <D2DSectionSelectBranch
            type={CONSTANTS.D2D_DELIVERY_ADDRESS1}
            setMapLocations={setMapLocations}
            setValidDistance={setValidDistance}
          />
        )}

        {isLoaded && additionaDeliveryAddresses && (
          <D2DSectionSelectBranch
            type={CONSTANTS.D2D_DELIVERY_ADDRESS2}
            setMapLocations={setMapLocations}
            setValidDistance={setValidDistance}
          />
        )}
      </div>

      {validDistance && (
        <div className="grid gap-3 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8 lg:py-3">
          <div className="col-span-12 ">
            {isLoaded ? (
              <>
                <div className="rounded-md bg-gray-100 mb-4 text-sm">
                  <div className="w-1/2 float-left">
                    <p className="font-semibold">PICK-UP</p>
                    <p>{details.PICKUP_BRANCH_ADDRESS}</p>

                    {details.COLLECTIONADDRESS1_BRANCH_ADDRESS !== null && (
                      <p>
                        <span className="font-semibold">
                          Pick-up Address 1:
                        </span>{" "}
                        {details.COLLECTIONADDRESS1_BRANCH_ADDRESS}
                      </p>
                    )}
                    {details.COLLECTIONADDRESS2_BRANCH_ADDRESS !== null && (
                      <p>
                        <span className="font-semibold">
                          Pick-up Address 2:
                        </span>{" "}
                        {details.COLLECTIONADDRESS2_BRANCH_ADDRESS}
                      </p>
                    )}
                  </div>
                  <div className="w-1/2 float-left">
                    <p className="font-semibold">DROP-OFF</p>

                    <p>{details.DROPOFF_BRANCH_ADDRESS}</p>
                    {details.DELIVERYADDRESS1_BRANCH_ADDRESS !== null && (
                      <p>
                        <span className="font-semibold">
                          Delivery Address 1:
                        </span>{" "}
                        {details.DELIVERYADDRESS1_BRANCH_ADDRESS}
                      </p>
                    )}
                    {details.DELIVERYADDRESS2_BRANCH_ADDRESS !== null && (
                      <p>
                        <span className="font-semibold">
                          Delivery Address 2:
                        </span>{" "}
                        {details.DELIVERYADDRESS2_BRANCH_ADDRESS}
                      </p>
                    )}
                  </div>
                  <div className="clear-both"></div>
                </div>
                <D2DGoogleMap mapLocations={mapLocations} />
              </>
            ) : (
              <p>Loading Map...</p>
            )}
          </div>
        </div>
      )}

      <div className="grid gap-3 px-5 py-5 border-t-2 border-gray-200 lg:grid-cols-12 lg:px-8 lg:py-3">
        <div className="col-span-12 ">
          {validDistance ? (
            <button
              type="button"
              disabled={showLoader}
              onClick={() => checkAvailability()}
              className={`float-right btn-navy ${
                showLoader
                  ? `bg-gray-300 hover:bg-gray-300`
                  : `bg-blue-900 hover:bg-blue-900`
              } `}
            >
              Select Vehicle
            </button>
          ) : (
            <button
              type="button"
              disabled={disableDistanceButton}
              onClick={handleCalculateDistance}
              className={`float-right btn-navy ${
                showLoader || disableDistanceButton
                  ? `bg-gray-300 hover:bg-gray-300`
                  : `bg-blue-900 hover:bg-blue-900`
              } `}
            >
              Calculate Distance
            </button>
          )}

          {showLoader && (
            <div className="mt-2 float-right">
              <Loader />
            </div>
          )}
        </div>
        {errorMessage && (
          <div className="col-span-12 text-red-500 text-right">
            {errorMessage}
          </div>
        )}
      </div>
    </>
  );
};
export default D2DGetQuoteDetails;
