import { useState, useEffect } from "react";
import UserProfileDetails from "components/user-profile/user-profile-details";
import UserReservations from "components/user-profile/user-reservations";
import UserQuotes from "components/user-profile/user-quotes";
import GetQuoteDetails from "components/get-quote/get-quote-details";
import { useHistory } from "react-router-dom";
import D2DGetQuoteDetails from "components/d2d/d2d-get-quote-details";

const UserProfile = ({ activeTab = 1 }) => {
  const [selectedTab, setSelectedTab] = useState(activeTab);
  const [manageDetails, setManageDetails] = useState({});
  let history = useHistory();
  let userData = JSON.parse(sessionStorage.getItem("sessionUser"));
  console.log(userData);

  useEffect(() => {
    if (
      sessionStorage.getItem("sessionUser") === null ||
      sessionStorage.getItem("sessionUser") === undefined
    ) {
      history.push("/login");
    }
  });

  return (
    <>
      <div className="rounded-md bg-gray-100 text-sm">
        <nav
          className="relative z-0 rounded-md shadow flex divide-x divide-gray-200"
          aria-label="Tabs"
        >
          <div
            onClick={() => setSelectedTab(1)}
            className={`${
              selectedTab === 1 ? `quote-tabs-active` : `quote-tabs-inactive`
            } quote-tabs rounded-tl-md min-w-0 bg-bl`}
            aria-current="page"
          >
            <span>My Profile</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 1 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>

          <div
            onClick={() => setSelectedTab(2)}
            className={`${
              selectedTab === 2 ? `quote-tabs-active` : `quote-tabs-inactive`
            } quote-tabs`}
          >
            <span>My Reservations</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 2 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>

          <div
            onClick={() => setSelectedTab(3)}
            className={`${
              selectedTab === 3 ? `quote-tabs-active` : `quote-tabs-inactive`
            } quote-tabs`}
          >
            <span>Quote Requests</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 3 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>

          <div
            onClick={() => setSelectedTab(4)}
            className={`${
              selectedTab === 4 ? `quote-tabs-active` : `quote-tabs-inactive`
            } rounded-tr-md quote-tabs`}
          >
            <span>Make Reservation</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 4 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>
          <div
            onClick={() => setSelectedTab(5)}
            className={`${
              selectedTab === 5 ? `quote-tabs-active` : `quote-tabs-inactive`
            } rounded-tr-md quote-tabs`}
          >
            <span>D2D Reservation</span>
            <span
              aria-hidden="true"
              className={`${
                selectedTab === 5 ? `bg-blue-900` : `bg-transparent`
              } quote-tabs-aria`}
            />
          </div>
        </nav>

        {selectedTab === 1 && (
          <>
            <UserProfileDetails />
          </>
        )}

        {selectedTab === 2 && (
          <>
            <UserReservations />
          </>
        )}

        {selectedTab === 3 && <UserQuotes />}
        <>
          {selectedTab === 5 && (
            <D2DGetQuoteDetails
              manageDetails={manageDetails}
              setManageDetails={setManageDetails}
            />
          )}
        </>
        <>
          {selectedTab === 4 && (
            <GetQuoteDetails
              manageDetails={manageDetails}
              setManageDetails={setManageDetails}
            />
          )}
        </>
      </div>
    </>
  );
};
export default UserProfile;
