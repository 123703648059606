import { useState, useEffect } from "react";
import { CONSTANTS } from "contants/constants";
import { ApiConsumer } from "api/ApiConsumer";
import * as Sentry from "@sentry/react";
import Loader from "components/loader";
import { useUtils } from "hooks/utils";
import Modals from "components/modals/index";
import ChatButton from "components/share/chat-button";

const ContactForm = () => {
  const [visibleModal, setVisibleModal] = useState("");
  const [pagePostModalSlug, setPagePostModalSlug] = useState("");
  const [loginAccountType, setLoginAccountType] = useState(
    CONSTANTS.LOGIN_ACCOUNT_TYPE.CORPORATE
  );

  const [contactDetails, setContactDetails] = useState({});
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [validContactDetails, setValidContactDetails] = useState({});
  const [termsChecked, setTermsChecked] = useState(false);
  let { validEmail, validNumeric } = useUtils();
  const [formSubmitted, setFormSubmitted] = useState(false);

  const validateCustomerDetails = () => {
    let invalidFieldFound = false;
    setError("");

    const formFields = [
      { name: "contactEnquiry", displayName: "Enquiry type" },
      { name: "contactFullname", displayName: "Full name" },
      { name: "contactNumber", displayName: "Contact number" },
      { name: "contactEmail", displayName: "Email address" },
      { name: "contactSubject", displayName: "Subject" },
      { name: "contactMessage", displayName: "Message" },
      {
        name: "consent",
        displayName:
          "you have read and accept the Website Terms and Conditions",
      },
    ];

    formFields.map((field, i) => {
      if (
        !invalidFieldFound &&
        (contactDetails[field.name] === undefined ||
          contactDetails[field.name].length === 0 ||
          (typeof contactDetails[field.name] === "string" &&
            contactDetails[field.name].trim() === "") ||
          contactDetails[field.name] === "selected")
      ) {
        setValidContactDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));

        if (
          field.name === "contactEnquiry" ||
          contactDetails[field.name] === "selected"
        )
          setError("Please select " + field.displayName + ".");
        else if (field.name === "consent" && !termsChecked)
          setError("Please confirm " + field.displayName + ".");
        else setError("Please enter your " + field.displayName + ".");

        invalidFieldFound = true;
      } else if (
        !invalidFieldFound &&
        ((field.name === "contactNumber" &&
          contactDetails[field.name].length &&
          !validNumeric(contactDetails[field.name])) ||
          (field.name === "contactEmail" &&
            contactDetails[field.name].length &&
            !validEmail(contactDetails[field.name])))
      ) {
        setValidContactDetails((prevState) => ({
          ...prevState,
          [field.name]: true,
        }));
        setError("Please enter a valid  " + field.displayName + ".");
        invalidFieldFound = true;
      } else {
        setValidContactDetails((prevState) => ({
          ...prevState,
          [field.name]: false,
        }));
      }
    });

    if (invalidFieldFound) return false;
    else {
      submit();
    }
  };

  function submit() {
    setLoading(true);

    //Send confirmation email
    ApiConsumer.post(
      "https://weblogs.bluevinegroup.co.za/user/send-external-contact/ajax/",
      JSON.stringify({ contactDetails })
    )
      .then((res) => {
        if (res.data.error !== undefined) setError(res.data.error);
        else setFormSubmitted(true);
        setLoading(false);
        window.scrollTo(0, 0);
      })
      .catch((error) => Sentry.captureException(error))
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    setError("");
    setValidContactDetails({});
    setFormSubmitted(false);
  }, [contactDetails]);

  return (
    <>
      <Modals
        visibleModal={visibleModal}
        setVisibleModal={setVisibleModal}
        loginAccountType={loginAccountType}
        setLoginAccountType={setLoginAccountType}
        pagePostModalSlug={pagePostModalSlug}
        setPagePostModalSlug={setPagePostModalSlug}
      />

      <div className="container m-auto max-w-screen-xl">
        <div className="flex flex-wrap">
          <div className="lg:w-8/12 pb-10 px-4 lg:px-6">
            {formSubmitted ? (
              <div className="col-span-12">
                <div
                  className="bg-green-100 border-t-4 border-green-300 rounded-b-lg p-6 shadow-sm mb-5"
                  role="alert"
                >
                  <div className="flex">
                    <div>
                      <p>
                        <strong>Thank You.</strong> Your mail has been
                        successfully sent.
                        <br />
                        One of our service consults will get in touch with you
                        soon.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <form
                onSubmit={(event) => {
                  event.preventDefault();
                  return validateCustomerDetails();
                }}
                method="POST"
              >
                <div className=" pb-4 bg-white">
                  <div className="grid gap-4 lg:grid-cols-12">
                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="contactEnquiry"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Enquiry type: <span className="text-red-600">*</span>
                      </label>
                      <select
                        name="contactEnquiry"
                        id="contactEnquiry"
                        defaultValue="General enquiry"
                        autoComplete="title"
                        className={`style-select ${
                          validContactDetails.contactEnquiry
                            ? `border-red-700`
                            : `border-gray-300`
                        }`}
                        onChange={(event) => {
                          setContactDetails((prevState) => ({
                            ...prevState,
                            ["contactEnquiry"]: event.target.value,
                          }));
                        }}
                        value={contactDetails.contactEnquiry}
                      >
                        <option value="General enquiry">General enquiry</option>
                        <option value="Sales enquiry">Sales enquiry</option>
                        <option value="Customer care">Customer care</option>
                        <option value="Reservations">Reservations</option>
                        <option value="Website support">Website support</option>
                      </select>
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="contactFullname"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Full name: <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="contactFullname"
                        name="contactFullname"
                        type="text"
                        className={`style-input ${
                          validContactDetails.contactFullname
                            ? `border-red-700`
                            : `border-gray-300`
                        }`}
                        onChange={(event) => {
                          setContactDetails((prevState) => ({
                            ...prevState,
                            ["contactFullname"]: event.target.value,
                          }));
                        }}
                        value={contactDetails.contactFullname}
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="contactNumber"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Contact number: <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="contactNumber"
                        name="contactNumber"
                        type="text"
                        className={`style-input ${
                          validContactDetails.contactNumber
                            ? `border-red-700`
                            : `border-gray-300`
                        }`}
                        onChange={(event) => {
                          setContactDetails((prevState) => ({
                            ...prevState,
                            ["contactNumber"]: event.target.value,
                          }));
                        }}
                        value={contactDetails.contactNumber}
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-6">
                      <label
                        htmlFor="contactEmail"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address: <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="contactEmail"
                        name="contactEmail"
                        type="text"
                        onChange={(event) => {
                          setContactDetails((prevState) => ({
                            ...prevState,
                            ["contactEmail"]: event.target.value,
                          }));
                        }}
                        className={`style-input ${
                          validContactDetails.contactEmail
                            ? `border-red-700`
                            : `border-gray-300`
                        }`}
                        value={contactDetails.contactEmail}
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-12">
                      <label
                        htmlFor="contactSubject"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Subject: <span className="text-red-600">*</span>
                      </label>
                      <input
                        id="contactSubject"
                        name="contactSubject"
                        type="text"
                        onChange={(event) => {
                          setContactDetails((prevState) => ({
                            ...prevState,
                            ["contactSubject"]: event.target.value,
                          }));
                        }}
                        className={`style-input ${
                          validContactDetails.contactSubject
                            ? `border-red-700`
                            : `border-gray-300`
                        }`}
                        value={contactDetails.contactSubject}
                      />
                    </div>

                    <div className="col-span-12 lg:col-span-12">
                      <label
                        htmlFor="contactMessage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Message:{" "}
                      </label>
                      <textarea
                        id="contactMessage"
                        name="contactMessage"
                        rows="3"
                        className="mt-1 p-3 focus:outline-none border border-gray-300 block w-full sm:text-sm rounded-xl"
                        onChange={(event) => {
                          setContactDetails((prevState) => ({
                            ...prevState,
                            ["contactMessage"]: event.target.value,
                          }));
                        }}
                        value={contactDetails.contactMessage}
                      />
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 bg-gray-100 text-right sm:px-6 rounded-bl-lg rounded-br-lg">
                  {error.length !== 0 && (
                    <div className="col-span-12 text-red-500 text-right py-2">
                      {error}
                    </div>
                  )}

                  <div className="clear-both" />

                  <label className=" float-left rounded-full text-sm cursor-pointer focus:outline-none py-2 lg:mx-2">
                    <input
                      type="checkbox"
                      name="consent"
                      value={!termsChecked ? "Terms &amp; Conditions" : ""}
                      className="h-4 w-4 mt-0.5 cursor-pointer"
                      aria-labelledby="terms-conditions"
                      onChange={(event) => {
                        setContactDetails((prevState) => ({
                          ...prevState,
                          ["consent"]: event.target.value,
                        }));
                      }}
                      onClick={() =>
                        setTermsChecked(termsChecked ? false : true)
                      }
                      checked={termsChecked}
                    />
                    <span className="ml-1">
                      I have read and accept the{" "}
                      <span
                        onClick={() => {
                          setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST);
                          setPagePostModalSlug("terms-and-conditions");
                        }}
                        className="text-blue-400 hover:underline cursor-pointer"
                      >
                        Website Terms and Conditions
                      </span>{" "}
                      and{" "}
                      <span
                        onClick={() => {
                          setVisibleModal(CONSTANTS.MODAL_TYPE.PAGEPOST);
                          setPagePostModalSlug("privacy-policy");
                        }}
                        className="text-blue-400 hover:underline cursor-pointer"
                      >
                        Privacy Policy
                      </span>
                    </span>
                  </label>

                  <button
                    type="submit"
                    disabled={loading}
                    className={`float-right btn-navy ${
                      loading
                        ? `bg-gray-300 hover:bg-gray-300`
                        : `bg-blue-900 hover:bg-blue-900`
                    } focus:outline-none`}
                  >
                    Submit
                  </button>
                  {loading && (
                    <div className="mt-2 float-right">
                      <Loader />
                    </div>
                  )}
                  <div className="clear-both" />
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <ChatButton />
    </>
  );
};
export default ContactForm;
