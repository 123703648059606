import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
} from "react";
import {
  GoogleMap,
  DirectionsService,
  DirectionsRenderer,
  DistanceMatrixService,
  Marker,
} from "@react-google-maps/api";
import { DetailsContext } from "contexts/details";
import { CONSTANTS } from "contants/constants";

const D2DGoogleMap = ({ mapLocations }) => {
  const [mapDistanceLocations, setMapDistanceLocations] = useState([]);
  const [mapDirectionsLocations, setMapDirectionsLocations] = useState([]);

  const { details, setDetails } = useContext(DetailsContext);

  const [mapDirectionsResponse, setMapDirectionsResponse] = useState(null);
  const [directionsLoaded, setDirectionsLoaded] = useState(false);

  const [distanceLoaded, setDistanceLoaded] = useState(false);
  const [totalDistance, setTotalDistance] = useState(0);

  const [pickUpToDropOffDistance, setPickUpToDropOffDistance] = useState({});
  const [depotToLocationDistance, setDepotToLocationDistance] = useState({});

  const [directions, setDirections] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  let D2DBranches = CONSTANTS.D2D_VEHICLES;

  let D2DBranchAddresses = [];
  D2DBranchAddresses = D2DBranches.filter(
    (branch) => branch.status === "active"
  );
  D2DBranchAddresses = D2DBranchAddresses.map((branch) => {
    return branch.address;
  });

  const containerStyle = {
    width: "100%",
    height: "300px",
  };

  var mapOptions = {
    zoom: 7,
    center: { lat: -26.109498, lng: 28.053556 },
    disableDefaultUI: true,
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: true,
    rotateControl: true,
    fullscreenControl: true,
  };

  const mapRef = useRef(null);
  const [bounds, setBounds] = useState(null);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const directionsCallback = useCallback(
    (response, status) => {
      if (response !== null && status === "OK" && !directionsLoaded) {
        setMapDirectionsResponse(response);
        setDirections(response);
        setDirectionsLoaded(true);

        let totalPickupToDropoff = 0;
        response.routes[0].legs.forEach((leg) => {
          totalPickupToDropoff += leg.distance.value;
        });

        setPickUpToDropOffDistance({
          text: `${(totalPickupToDropoff / 1000).toFixed(1)} km`,
          value: totalPickupToDropoff,
        });

        const bounds = new window.google.maps.LatLngBounds();
        response.routes[0].legs.forEach((leg) => {
          bounds.extend(leg.start_location);
          bounds.extend(leg.end_location);
        });

        if (mapRef.current) {
          mapRef.current.fitBounds(bounds, {
            padding: { top: 50, right: 50, bottom: 50, left: 50 },
          });
        }

        setBounds(bounds);

        const origins = [
          response.routes[0].legs[0].start_location,
          response.routes[0].legs[response.routes[0].legs.length - 1]
            .end_location,
        ];
        setMapDistanceLocations(origins);
      }
    },
    [directionsLoaded, mapRef]
  );

  const distanceMatrixCallback = useCallback(
    (response, status) => {
      if (response !== null && status === "OK" && !distanceLoaded) {
        setDistanceLoaded(true);
        try {
          findClosestBranch(response);
        } catch (error) {
          console.error("Error calculating distances:", error);
          setErrorMessage(
            "Error calculating distances. Please try selecting your locations again."
          );
        }
      }
    },
    [distanceLoaded, pickUpToDropOffDistance]
  );

  const findClosestBranch = useCallback(
    (response) => {
      let origins = response.originAddresses;
      let destinations = response.destinationAddresses;
      let rows = response.rows;

      let depotToLocation = { pickup: [], dropoff: [] };

      // Add data validation
      if (!rows || !rows.length || !rows[0].elements || !rows[1]?.elements) {
        console.error("Invalid distance matrix response");
        return;
      }

      rows.forEach((row, i) => {
        row.elements.forEach((element, j) => {
          if (element.status === "OK" && element.distance) {
            depotToLocation[i === 0 ? `pickup` : `dropoff`].push({
              location: origins[i],
              branch: destinations[j],
              branchCode: D2DBranches[j].code,
              distance: element.distance,
            });
          }
        });

        if (depotToLocation[i === 0 ? `pickup` : `dropoff`].length > 0) {
          depotToLocation[i === 0 ? `pickup` : `dropoff`].sort((a, b) =>
            a.distance.value > b.distance.value ? 1 : -1
          );
        }
      });

      if (!depotToLocation.pickup.length || !depotToLocation.dropoff.length) {
        console.error("No valid routes found to depots");
        return;
      }

      depotToLocation.dropoff = depotToLocation.dropoff.filter(
        (x) => x.branchCode === depotToLocation.pickup[0].branchCode
      );

      if (!depotToLocation.dropoff.length) {
        console.error("No matching depot found");
        return;
      }

      if (
        depotToLocation.pickup[0]?.distance?.value == null ||
        pickUpToDropOffDistance?.value == null ||
        depotToLocation.dropoff[0]?.distance?.value == null
      ) {
        console.error("Missing distance values");
        return;
      }

      const totalDistance =
        depotToLocation.pickup[0].distance.value +
        pickUpToDropOffDistance.value +
        depotToLocation.dropoff[0].distance.value;

      setDetails((prevState) => ({
        ...prevState,
        tripKms: parseInt(totalDistance / 1000),
        PICKUP_BRANCH: depotToLocation.pickup[0].branchCode,
        DROPOFF_BRANCH: depotToLocation.dropoff[0].branchCode,
      }));

      setDepotToLocationDistance(depotToLocation);
      setTotalDistance(totalDistance);
    },
    [pickUpToDropOffDistance, setDetails]
  );

  useEffect(() => {
    return () => {
      setDirectionsLoaded(false);
      setDistanceLoaded(false);
      setDirections(null);
      setErrorMessage("");
      setPickUpToDropOffDistance({});
      setDepotToLocationDistance({});
      setTotalDistance(0);
      setMapDistanceLocations([]);
      setMapDirectionsLocations([]);
    };
  }, []);

  useEffect(() => {
    if (!mapLocations.pickup || !mapLocations.dropoff) return;

    setDirectionsLoaded(false);
    setDistanceLoaded(false);
    setDirections(null);
    setErrorMessage("");
    setPickUpToDropOffDistance({});
    setDepotToLocationDistance({});
    setTotalDistance(0);

    setDetails((prevState) => ({
      ...prevState,
      [`tripKms`]: 0,
    }));

    const newDirectionsLocations = convertDirectionsLocations(mapLocations);
    setMapDirectionsLocations(newDirectionsLocations);
  }, [mapLocations.pickup, mapLocations.dropoff]);

  const convertDistanceLocations = (mapLocations) => {
    let tempArray = [];

    Object.keys(mapLocations).forEach(function (key) {
      if (mapLocations[key]?.geometry?.location) {
        tempArray.push(mapLocations[key].geometry.location);
      }
    });
    return tempArray;
  };

  const convertDirectionsLocations = (mapLocations) => {
    const waypointKeys = [
      "collectionaddress1",
      "collectionaddress2",
      "deliveryaddress1",
      "deliveryaddress2",
    ];

    return waypointKeys
      .filter((key) => mapLocations[key]?.geometry?.location)
      .map((key) => ({
        location: mapLocations[key].place_id
          ? { placeId: mapLocations[key].place_id }
          : mapLocations[key].geometry.location,
        stopover: true,
      }));
  };

  // Update marker display logic
  const renderMarkers = () => {
    const markers = [];

    // Only show markers if we're not showing directions
    if (!directionsLoaded) {
      if (mapLocations.pickup?.geometry?.location) {
        markers.push(
          <Marker
            key="pickup"
            position={mapLocations.pickup.geometry.location}
            title="Pick-up Location"
          />
        );
      }

      if (mapLocations.dropoff?.geometry?.location) {
        markers.push(
          <Marker
            key="dropoff"
            position={mapLocations.dropoff.geometry.location}
            title="Drop-off Location"
          />
        );
      }

      // Add markers for waypoints
      [
        "collectionaddress1",
        "collectionaddress2",
        "deliveryaddress1",
        "deliveryaddress2",
      ].forEach((key) => {
        if (mapLocations[key]?.geometry?.location) {
          markers.push(
            <Marker
              key={key}
              position={mapLocations[key].geometry.location}
              title={key.charAt(0).toUpperCase() + key.slice(1)} // Capitalize first letter
            />
          );
        }
      });
    }

    return markers;
  };

  return (
    <>
      {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}

      {Object.keys(pickUpToDropOffDistance).length &&
      Object.keys(depotToLocationDistance).length ? (
        <div className="mb-2">
          <div className="text-sm mb-1">
            <strong>Route Details:</strong>
            <br />
            Depot to Pick-up: {depotToLocationDistance.pickup[0].distance.text}
            <br />
            Total Route Distance: {pickUpToDropOffDistance.text}
            <br />
            Drop-off to Depot:{" "}
            {depotToLocationDistance.dropoff[0].distance.text}
          </div>
          <span className="font-semibold">
            TOTAL TRANSFER: {parseInt(totalDistance / 1000)} km
          </span>
        </div>
      ) : null}

      <GoogleMap
        mapContainerStyle={containerStyle}
        {...mapOptions}
        onLoad={onMapLoad}
      >
        {renderMarkers()}

        {mapLocations.pickup && mapLocations.dropoff && (
          <>
            <DirectionsService
              options={{
                origin: mapLocations.pickup.place_id
                  ? { placeId: mapLocations.pickup.place_id }
                  : mapLocations.pickup.geometry.location,
                destination: mapLocations.dropoff.place_id
                  ? { placeId: mapLocations.dropoff.place_id }
                  : mapLocations.dropoff.geometry.location,
                waypoints: mapDirectionsLocations,
                optimizeWaypoints: false,
                travelMode: "DRIVING",
              }}
              callback={directionsCallback}
            />

            {directionsLoaded && directions && (
              <>
                <DirectionsRenderer
                  options={{
                    directions: directions,
                    suppressMarkers: false,
                    preserveViewport: false,
                    polylineOptions: {
                      strokeColor: "#2563eb",
                      strokeOpacity: 0.8,
                      strokeWeight: 5,
                    },
                  }}
                />

                {!distanceLoaded &&
                  mapDistanceLocations.length > 0 &&
                  pickUpToDropOffDistance.value && (
                    <DistanceMatrixService
                      options={{
                        origins: mapDistanceLocations,
                        destinations: D2DBranchAddresses,
                        travelMode: "DRIVING",
                        avoidHighways: false,
                        avoidTolls: false,
                      }}
                      callback={distanceMatrixCallback}
                    />
                  )}
              </>
            )}
          </>
        )}
      </GoogleMap>
    </>
  );
};

export default D2DGoogleMap;
